// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-episode-js": () => import("../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-band-template-js": () => import("../src/templates/bandTemplate.js" /* webpackChunkName: "component---src-templates-band-template-js" */),
  "component---src-templates-gallery-js": () => import("../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-roster-js": () => import("../src/pages/roster.js" /* webpackChunkName: "component---src-pages-roster-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-management-js": () => import("../src/pages/services/management.js" /* webpackChunkName: "component---src-pages-services-management-js" */)
}

